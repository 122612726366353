import axios from "axios";
import { apiErrorHandler } from "./apiErrorHandler";

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_DEVELOPMENT_URL,
	headers: {
		accept: "*/*",
		Authorization: ((localStorage.getItem("__admin__token&")) ? localStorage.getItem("__admin__token&") : sessionStorage.getItem("__admin__token&")) || '',
		// Authorization: localStorage.getItem("__admin__token&"),
	},
});

// Response interceptor for error handling
axiosInstance.interceptors.response.use(
	(response) => response, // Return response directly if no error
	(error) => {
		// Centralized error handling
		const handledError = apiErrorHandler(error);
		return Promise.reject(handledError); // Reject to propagate error to the caller
	}
);

export default axiosInstance;