import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";
import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
    this.iframeObserver = null;
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error", error, errorInfo);
    this.setState("Admin Panel",{ error, errorInfo });

    // Get user email from sessionStorage
    const userEmail = sessionStorage.getItem("parkingBudEmail");

    // Set user context for Sentry
    if (userEmail) {
      Sentry.setUser({ email: userEmail });
    }

    // Send error to Sentry with additional context
    Sentry.withScope((scope) => {
      // Add additional context if needed
      scope.setExtra("componentStack", errorInfo?.componentStack);
      scope.setExtra("errorInfo", errorInfo);

      // Set error level
      scope.setLevel("error");

      // Capture the error
      Sentry.captureException(error);
    });

    this.observeIframe();
  }

  componentDidMount() {
    this.observeIframe();

    // Optionally set user context when component mounts
    const userEmail = sessionStorage.getItem("parkingBudEmail");
    if (userEmail) {
      Sentry.setUser({ email: userEmail });
    }
  }

  componentWillUnmount() {
    if (this.iframeObserver) {
      this.iframeObserver.disconnect();
    }
  }

  logErrorToService(error, errorInfo) {
    // You can keep this for additional logging if needed
    console.log("Logged error to service:", error, errorInfo);
  }

  handleResetError = () => {
    // Clear Sentry context before resetting

    this.setState({ hasError: false, error: null, errorInfo: null });
    window.location.reload();
  };

  observeIframe = () => {
    const targetNode = document.body;
    this.iframeObserver = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList") {
          mutation.addedNodes.forEach((node) => {
            if (
              node.tagName === "IFRAME" &&
              node.style.position === "fixed" &&
              node.style.zIndex === "2147483647"
            ) {
              node.remove();
            }
          });
        }
      }
    });

    this.iframeObserver.observe(targetNode, { childList: true, subtree: true });
  };

  render() {
    if (this.state.hasError) {
      // Optionally send a breadcrumb when showing error UI
      Sentry.addBreadcrumb({
        category: "error-boundary",
        message: "Error boundary fallback rendered",
        level: "info",
      });

      return (
        <div className="error-boundary">
          <div className="login">
            <div className="container" style={{ height: "21rem" }}>
              <div className="row m-0 justify-content-center align-items-center">
                {/* <LottieGIF
                  json={loader}
                  loop={true}
                  height="8rem"
                  width="8rem"
                /> */}
              </div>
              <h2 id="header">
                {this.props.fallback || "Something went wrong."}
              </h2>
              <p id="sub__header" style={{ textAlign: "center" }}>
                We're working on fixing the issue. Please try again later.
              </p>
              <div className="button__group">
                <button className="appButton" onClick={this.handleResetError}>
                  Try Again
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
};

// You can also use Sentry's built-in ErrorBoundary wrapper if you want
export default Sentry.withErrorBoundary(ErrorBoundary, {
  showDialog: false, // Set to true if you want to show Sentry's built-in error dialog
  dialogOptions: {
    subtitle: "If you need help, please contact support.",
  },
});
