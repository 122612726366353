
import { toast } from "react-toastify";


export const apiErrorHandler = (error) => {
    let errorMessage = "An unknown error occurred.";
    let errorStatus = null;

    if (!error.response) {
        // Network or request error
        console.error("Network/Request Error:", error.message);
        errorMessage = "Network error. Please check your connection.";
    } else {
        const { status, data } = error.response;
        errorStatus = status;

        switch (status) {
            case 400:
                errorMessage = data.message || "Invalid request.";
                break;
            case 401:
                // Handle 401 error: Clear storage and redirect to login
                errorMessage = data.message || "Unauthorized access. Please log in.";

                // Clear local storage/session storage
                localStorage.clear();
                sessionStorage.clear();

                window.location.href = "/login";

                break;
            case 403:
                errorMessage = data.message || "Access denied. You do not have permission.";
                break;
            case 404:
                errorMessage = data.message || "Resource not found.";
                break;
            case 500:
                errorMessage = data.message || "Internal server error. Please try again later.";
                break;
            default:
                errorMessage = data.message || "Something went wrong. Please try again.";
        }

        // Log details for debugging
        console.error(`Error ${status}:`, data);
    }

    // Display error in toaster
    toast.error(errorMessage);

    // Return structured error information
    return { message: errorMessage, status: errorStatus };
};
