import React from 'react';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react";

const isDev = process.env.REACT_APP_ENVIRONMENT === "development";
const isProd = process.env.REACT_APP_ENVIRONMENT === "production";

const getDomain = (url) => {
  try {
    return new URL(url).hostname;
  } catch {
    return "";
  }
};

const backendDomain = getDomain(process.env.REACT_APP_BACKEND_DEVELOPMENT_URL);
const urlList = isProd
  ? [
      process.env.REACT_APP_BACKEND_DEVELOPMENT_URL,
      new RegExp(`^https?://${backendDomain.replace(".", "\\.")}`),
      process.env.REACT_APP_BACKEND_DEVELOPMENT_URL_SOCKET,
      new RegExp(`^wss?://${backendDomain.replace(".", "\\.")}`),
      "/ws/",
    ]
  : [
      process.env.REACT_APP_BACKEND_DEVELOPMENT_URL_SOCKET,
      new RegExp(`^wss?://${backendDomain.replace(".", "\\.")}`),
      "/ws/",
    ];

Sentry.init({
  dsn: process.env.REACT_APP_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: process.env.REACT_APP_ENVIRONMENT,

  tracePropagationTargets: [
    ...urlList,
    isDev && "localhost",
    isDev && "127.0.0.1",
  ].filter(Boolean),

  tracesSampleRate: isProd ? 0.1 : 1.0,
  replaysSessionSampleRate: isProd ? 0.1 : 0.5,
  replaysOnErrorSampleRate: 1.0,

  beforeSend(event) {
    event.tags = {
      ...event.tags,
      backend_url: process.env.REACT_APP_BACKEND_DEVELOPMENT_URL,
      socket_url: process.env.REACT_APP_BACKEND_DEVELOPMENT_URL_SOCKET,
    };
    return event;
  },
});

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
