
import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosInstance";
import NotificationContext from "../../components/notification/NotificationContext";
import { useNavigate } from "react-router-dom";





// const _ = (classes) => {
//     let s = "";
//     classes.map((i) => (s += i + " "));
//     return s;
// };

var io = ""

var token = ((localStorage.getItem("__admin__token&")) ? localStorage.getItem("__admin__token&") : sessionStorage.getItem("__admin__token&")) || ''




const DashboardContext = (props) => {

    const history = useNavigate();

    
    const [ID, setID] = useState(-1)
    const [state, setState] = useState({
        id:null,
        first_name: "",
        last_name: "",
        email: "",
        profile_pic: null,
        phone_number: "",
        address_line_1: "",
        address_line_2: "",
        country: "",
        country_code: "",
        city: "",
        state: "",
        zip_code: "",
        timezone: "",
        
    })

    const[permis, setpermis]=useState({})
    const [contextData, setData] = useState();
    //const [dashboardNotification, setDashboard] = useState();

    async function makerequest() {

        await axiosInstance.get(
            "admin_dash/edit_admin_profile"
        ).then((response) => {

            setState(response.data.response.user)
            setID(response.data.response.user.id)

        }).catch((err) => {
            if(err?.message === 'Network Error') {
                sessionStorage.removeItem('__admin__token&');
                localStorage.removeItem('__admin__token&');
                history("/", { replace: true });
                window.location.reload();
            }
            console.log("errrrrr",err)
            console.log(err.response.data);

        })

    }

    async function permission() {

        await axiosInstance.get(
            "/permissions"
        ).then((response) => {
            setpermis(response.data.response)
        }).catch((err) => {
            console.log(err.response);

        })

    }
    const [email_data,setemaildata]=useState()



    async function email_permission(){
        await axiosInstance.get(
            'admin_dash/email_permission'
        ).then((response) => {
            let data = response.data.response.data;
            setemaildata(data)
    
        }).catch((err) => {
            console.log(err.response.data);
    
        })
    
        }

    useEffect(() => {
        // makerequest()
        // permission()
        // email_permission()

    }, [])


    //notification section start
    async function AdminNotifications() {

        io = new WebSocket(
            process.env.REACT_APP_BACKEND_DEVELOPMENT_URL_SOCKET
            + 'ws/admin_notifications/'
            + state.id +
            '/'
        );

        io.onopen = (e) => {
            if (io.readyState === WebSocket.OPEN) {
                io.send(JSON.stringify({ 'token': token }))
            }
        }
        io.onmessage = (e) => {
            let data = JSON.parse(e.data);
            setData(data)
            
        };
        // io.onclose=()=>{
        // }
        // return()=>{
        //     io.close()
        // }
    }

    const [activenotification,setactivenotification]=useState()
    const [activecount,setactivecount]=useState(0)

    async function Admin_activities() {
        let io = new WebSocket(
            process.env.REACT_APP_BACKEND_DEVELOPMENT_URL_SOCKET
            + 'ws/admin_activites/'
        );

        io.onopen = (e) => {
            if (io.readyState === WebSocket.OPEN) {
                io.send(JSON.stringify({ 'token': token }))
            }
        }
        io.onclose=()=>{
        }
        io.onmessage = (e) => {
            let data = JSON.parse(e.data);
            if (data.type === 'notification') {
                setactivecount(data.notifications_unread)
                setactivenotification(data.notifications)
            }
            
        };
        return () => {
            io.close();
        }

    }

    const [adminaccess,setadminaccess]=useState()
    const [accesscount,setaccesscount]=useState(0)

    async function Admin_access(){
        let io = new WebSocket(
            process.env.REACT_APP_BACKEND_DEVELOPMENT_URL_SOCKET
            + 'ws/admin_access/'
        );

        io.onopen = (e) => {
            if (io.readyState === WebSocket.OPEN) {
                io.send(JSON.stringify({ 'token': token }))
            }
            //io.send(JSON.stringify({'token':token}))
        }
        io.onclose=()=>{}
        io.onmessage = (e) => {
            let data = JSON.parse(e.data);
            setaccesscount(data.notifications_unread)
            setadminaccess(data.notifications)
        };
        return () => {
            io.close();
        }

    }
    useEffect(()=>{
        // Admin_activities()
        // Admin_access()
        // AdminNotifications()
    })


    
    return (
        <>
            <NotificationContext.Provider value={{ data: contextData, method: io, 
                profile:state,permis:permis,
                activecount:activecount,activenotification:activenotification,
                adminaccessnotify:adminaccess, adminaccesscount:accesscount,
                email_data:email_data }}>
                {props.children}
            </NotificationContext.Provider>
        </>
    )
}

export default DashboardContext;