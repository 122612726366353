import React, { memo, Suspense } from "react";
import Loadable from "react-loadable";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navz from '../components/Navbar1';
import DashboardContext from "../Dashboard_page/auth/DashboardContext";
import NotFound from "../Dashboard_page/NotFound";

// Lazy loading components
const TestComponent = React.lazy(() => import("./../components/lottieComponent"));
const MyProfileEdit = React.lazy(() => import("../components/myprofile/EditMyProfile/MyProfileEdit"));

const ProviderDetail = React.lazy(() => import("../components/providerdetail/ProviderDetail"));
const ProviderProfile = React.lazy(() => import("../components/providerdetail/profile/Profile"));
const ProviderBookings = React.lazy(() => import("../components/providerdetail/bookings/Bookings"));
const ProviderCoupons = React.lazy(() => import("../components/providerdetail/coupons/Coupons"));
const ProviderDocuments = React.lazy(() => import("../components/providerdetail/documents/Documents"));
const ProviderEarnings = React.lazy(() => import("../components/providerdetail/earnings/Earnings"));
const ProviderInquries = React.lazy(() => import("../components/providerdetail/inquiries/Inquries"));
const ProviderParkingSpot = React.lazy(() => import("../components/providerdetail/parkingspots/ParkingSpot"));

const UsersDetail = React.lazy(() => import("../components/usersdetails/UsersDetails"));
const UsersBookings = React.lazy(() => import("../components/usersdetails/bookings/UsersBookings"));
const UsersProfile = React.lazy(() => import("../components/usersdetails/profile/Profile"));

const Dashboard = React.lazy(() => import("../Dashboard_page/auth/Dashboard"));
const ParkingSpots = React.lazy(() => import('../components/Parking_spot/parkingspots/ParkingSpots'));
const Bookings = React.lazy(() => import('../components/Bookings/Bookings'));
const NotificationComponent = React.lazy(() => import('../components/notification/Notifications'));
const Users = React.lazy(() => import('../components/Users/Users'));
const AddParkingSpot = React.lazy(() => import('../components/Parking_spot/AddParkingSpot/AddParkingSpot'));
const BasicDetails = React.lazy(() => import('../components/Parking_spot/AddParkingSpot/BasicDetails'));
const ParkingSpotAddress = React.lazy(() => import('../components/Parking_spot/AddParkingSpot/ParkingSpotAddress'));
const OtherDetails = React.lazy(() => import('../components/Parking_spot//AddParkingSpot/OtherDetails'));
const AppliedForApproval = React.lazy(() => import('../components/Parking_spot/AddParkingSpot/AppliedForApproval'));
const ViewParkingSpot = React.lazy(() => import("../components/Parking_spot/parkingspots/ViewParkingSpot"));
const AdminDashbord = React.lazy(() => import('../components/admindashbord'));
const MapviewDashboard = React.lazy(() => import('../components/Parking_spot/googlemap/MapViewDashboard'));

const ParkingDataRouters = React.lazy(() => import("./authrouts/ParkingDataRouters"));
const CompanyProfileRouters = React.lazy(() => import("./authrouts/CompanyProfileRouters"));
const MyProfileRouters = React.lazy(() => import("./authrouts/MyProfileRouters"));
const TermsRouters = React.lazy(() => import("./authrouts/TermsRouters"));
const AboutusRouters = React.lazy(() => import("./authrouts/AboutusRouters"));
const ReportInquiryRouters = React.lazy(() => import("./authrouts/ReportInquiryRouters"));
const ViewBooking = React.lazy(() => import("../components/Bookings/ViewBookings"));
const ViewInvoice = React.lazy(() => import("../components/Bookings/ViewInvoice"));

// Authentication wrapper
const RequireAuth = ({ children }) => {
    const isAuthenticated = !!localStorage.getItem("__admin__token&") || !!sessionStorage.getItem("__admin__token&"); // Replace with your auth check
    return isAuthenticated ? children : <Navigate to="/login" replace />;
};

// Page components with lazy loading
const LoginPage = Loadable({
    loader: () => import("../Dashboard_page/public/admin/Login"),
    loading: () => (
        <div className="d-flex justify-content-center spinner">
            <div className="spinner-border text-muted mt-4 mb-4"></div>
        </div>
    ),
});

const ForgotPasswordPage = Loadable({
    loader: () => import("../Dashboard_page/public/admin/ForgotPassword"),
    loading: () => (
        <div className="d-flex justify-content-center spinner">
            <div className="spinner-border text-muted mt-4 mb-4"></div>
        </div>
    ),
});

const ForgotPassword2Page = Loadable({
    loader: () => import("../Dashboard_page/public/admin/ForgotPassword2"),
    loading: () => (
        <div className="d-flex justify-content-center spinner">
            <div className="spinner-border text-muted mt-4 mb-4"></div>
        </div>
    ),
});

const OtpVerficationPage = Loadable({
    loader: () => import("../Dashboard_page/public/admin/OTPVerification"),
    loading: () => (
        <div className="d-flex justify-content-center spinner">
            <div className="spinner-border text-muted mt-4 mb-4"></div>
        </div>
    ),
});

const ResetPasswordPage = Loadable({
    loader: () => import("../Dashboard_page/public/admin/ResetPassword"),
    loading: () => (
        <div className="d-flex justify-content-center spinner">
            <div className="spinner-border text-muted mt-4 mb-4"></div>
        </div>
    ),
});


function PublicRoute() {

    return (
        <Router>
            <Navz />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Suspense fallback={<div className="d-flex justify-content-center spinner"><div className="spinner-border text-muted mt-4 mb-4"></div></div>}>
                <DashboardContext>
                    <Routes>
                        <Route exact path='/' element={<LoginPage />} />
                        <Route exact path='/login' element={<LoginPage />} />
                        <Route exact path='/forgotpassword' element={<ForgotPasswordPage />} />
                        <Route path='/forgotpassword/email' element={<ForgotPassword2Page />} />
                        <Route path='/forgotpassword/phone' element={<ForgotPassword2Page />} />
                        <Route path='/forgotpassword/otpverification' element={<OtpVerficationPage />} />
                        <Route path='/forgotpassword/resetpassword' element={<ResetPasswordPage />} />
                        <Route path='/test' element={<TestComponent />} />


                        <Route path='/Dashboard/Mapview' element={<MapviewDashboard />} />
                        {/* Protected Routes */}
                        <Route
                            path="/Dashboard"
                            element={
                                <RequireAuth>
                                    <Dashboard />
                                </RequireAuth>
                            }
                        >
                            {/* Nested Routes */}
                            <Route path="details" element={<AdminDashbord />} />

                            {/* Users Details */}
                            <Route path="UsersDetail" element={<UsersDetail />}>
                                <Route path="UsersBookings" element={<UsersBookings />} />
                                <Route path="UsersProfile" element={<UsersProfile />} />
                            </Route>

                            {/* Parking Spots */}
                            <Route path="ParkingSpots" element={<ParkingSpots />} />
                            <Route path="AddParkingSpot" element={<AddParkingSpot />}>
                                <Route path="BasicDetails" element={<BasicDetails />} />
                                <Route path="ParkingSpotAddress" element={<ParkingSpotAddress />} />
                                <Route path="OtherDetails" element={<OtherDetails />} />
                                <Route path="AppliedForApproval" element={<AppliedForApproval />} />
                            </Route>
                            <Route path="ViewParkingSpot" element={<ViewParkingSpot />} />


                            {/* Notifications */}
                            <Route path="Notification" element={<NotificationComponent />} />

                            {/* Users */}
                            <Route path="Users" element={<Users />} />

                        </Route>

                        <Route path="/MyAccount/Edit" element={<MyProfileEdit />} />


                        {/* Bookings */}
                        <Route path="Bookings" element={<Bookings />} />
                        <Route path="ViewBooking" element={<ViewBooking />} />
                        <Route path="invoice" element={<ViewInvoice />} />

                        {/* Provider Details */}
                        <Route path="ProviderDetail" element={<ProviderDetail />}>
                            <Route index element={<ProviderProfile />} />
                            <Route path="Bookings" element={<ProviderBookings />} />
                            <Route path="Coupons" element={<ProviderCoupons />} />
                            <Route path="Documents" element={<ProviderDocuments />} />
                            <Route path="Earnings" element={<ProviderEarnings />} />
                            <Route path="Inquiries" element={<ProviderInquries />} />
                            <Route path="ParkingSpot" element={<ProviderParkingSpot />} />
                        </Route>

                        <Route
                            path="/company-profile/*"
                            element={
                                <RequireAuth>
                                    <CompanyProfileRouters />
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="/ParkingData/*"
                            element={
                                <RequireAuth>
                                    <ParkingDataRouters />
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="/MyProfile/*"
                            element={
                                <RequireAuth>
                                    <MyProfileRouters />
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="/Terms/*"
                            element={
                                <RequireAuth>
                                    <TermsRouters />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/AboutUs/*"
                            element={
                                <RequireAuth>
                                    <AboutusRouters />
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="/ReportInquries/*"
                            element={
                                <RequireAuth>
                                    <ReportInquiryRouters />
                                </RequireAuth>
                            }
                        />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </DashboardContext>
            </Suspense>
        </Router>
    );
}

export default memo(PublicRoute);
