import PublicRoute from './routes/PublicRoutes';
import { Toaster } from 'react-hot-toast';
import './App.css'

import ForgotPass from './store/public/forgotPassword/forgotPass';
import BusinessInfo from './store/public/BusinessInfo/BusinessInfo';
import BusinessInfo2 from './store/public/BusinessInfo2/BusinessInfo';
import BusinessInfo3 from './store/public/BusinessSignup/BusinessInfo';
import { Provider } from 'react-redux';
import store from './store/store';
import loadGoogleMapsAPI from './utils/loadGoogleMapsAPI';
import { useEffect } from 'react';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

function App() {

  useEffect(() => {
    // Load the Google Maps API when the app is loaded
    loadGoogleMapsAPI();
  }, []);

  return (
    <Provider store={store}>
      <ForgotPass>
        <Toaster />
        <BusinessInfo>
          <BusinessInfo2>
            <BusinessInfo3>
              <ErrorBoundary>
                <PublicRoute />
              </ErrorBoundary>
            </BusinessInfo3>
          </BusinessInfo2>
        </BusinessInfo>
      </ForgotPass>
    </Provider>

  );
}

export default App;

